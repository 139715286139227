<template>
  <div>
    <v-btn class="cust-btn-color" @click="onPrint"
      ><v-icon class="mr-2"> mdi-printer </v-icon> {{ $t('resultPayment.print') }}
    </v-btn>
  </div>
</template>

<script>
import { formatNumber } from '@/plugins/global-funcs'
import '../assets/font/Kanit-Regular-normal'
import moment from 'moment'
import { part_type } from '../config'
import { jsPDF } from 'jspdf'
import { currency_symbol } from '../config'
import 'jspdf-autotable'
export default {
  name: 'OrderExport',
  props: {
    cartDetail: {
      default: null
    },
    inCart: {
      default: null
    },
    addressBilling: {
      default: null
    },
    deliveryCost: {
      default: null
    },
    total: {
      default: null
    },
    currency: {
      default: null
    },
    sell_rate: {
      default: null
    },
    vat: {
      default: null
    }
  },
  data() {
    return {
      firstRender: true,
      partType: part_type,
      mainAddress: {
        fullName: '',
        addressName: '',
        cityName: '',
        countryName: '',
        zipCode: ''
      },
      currency_symbol: currency_symbol,
      cart: null
    }
  },
  computed: {
    subTotal: function () {
      let sum = 0.0
      for (const x of this.inCart) {
        let price = this.specificExchangeRate(x.price_per_piece)
        sum += parseFloat(price * x.quantity, 2)
      }
      return parseFloat(sum) || parseFloat(sum) == 0 ? sum : '-'
    },
    totalIncludedTax: function () {
      let total =
        parseFloat(this.subTotal) +
        parseFloat(this.specificExchangeRate(this.deliveryCost))
      return parseFloat(total) || parseFloat(total) == 0 ? total : '-'
    }
  },
  created() {
    this.mainAddress = this.setAddress(this.addressBilling)
    console.log('address', this.mainAddress)
    this.cart = this.inCart.map((x) => {
      return [
        {
          content: `${
            x.group_part ? x.group_part.motor_model.brand.name : ''
          } \n ${x.group_part ? x.group_part.motor_model.name : ''}`,
          styles: { halign: 'center' }
        },
        {
          content: `${
            x.group_part ? this.getGroupPartName(x.group_part.type) : ''
          } \n ${x.group_part ? x.group_part.name : ''}`,
          styles: { halign: 'center' }
        },
        {
          content: x.group_part_part ? x.group_part_part[0].number : '',
          styles: { halign: 'center' }
        },
        x.part.sku,
        x.part.name,
        {
          content: x.quantity.toString(),
          styles: { halign: 'right' }
        },
        {
          content: formatNumber(
            this.specificExchangeRate(x.price_per_piece),
            2
          ),
          styles: { halign: 'right' }
        },
        {
          content: formatNumber(this.specificExchangeRate(x.total_price), 2),
          styles: { halign: 'right' }
        }
      ]
    })
  },
  methods: {
    setAddress(model) {
      switch (model.address_type) {
        case 'thai':
          return {
            fullName: model.fullName_th,
            addressName: model.address_th,
            cityName: model.province_th,
            countryName: model.district_th,
            zipCode: model.post_code_th,
            mobilePhone: model.phone_th
          }
        case 'korea':
          return {
            fullName: model.fullName_kr,
            addressName: model.address_kr,
            cityName: model.province_kr,
            countryName: model.district_kr,
            zipCode: model.post_code_kr,
            mobilePhone: model.phone_kr
          }
        case 'shop':
          return {
            fullName: "Hello Custom",
            addressName: "258/6 Soi 12 Praram 3 Bang Khlo",
            cityName: "Bang Kho Laem, Bangkok",
            countryName: "Thailand",
            zipCode: "10120",
            mobilePhone: "+66 95 689 4280",
          };
        case 'other':
          return model
      }
    },
    toCurrencyLable(currency) {
      return this.currency_symbol[currency]
    },
    getGroupPartName(id) {
      const partTypeModel = this.partType.find((x) => x.id === parseInt(id))
      return partTypeModel ? partTypeModel.name : ''
    },
    addVAT(price) {
      return price + price * this.vat
    },
    specificExchangeRate(thb_price) {
      if(this.currency === 'USD') return Math.ceil((this.sell_rate * thb_price) * 10) / 10;
      else return Math.ceil(this.sell_rate * thb_price);
    },
    onPrint() {
      if (this.firstRender) {
        let summary = [
          [
            null,
            null,
            null,
            null,
            null,
            null,
            {
              content: 'VAT(7%)',
              styles: { halign: 'right' }
            },
            {
              content: `${this.toCurrencyLable(this.currency)} ${formatNumber(
                  (this.cartDetail.total_price - (this.cartDetail.total_price * 100 / 107))*this.sell_rate ,
                2
              )} `,
              styles: { halign: 'right' }
            }
          ],
          [
            null,
            null,
            null,
            null,
            null,
            null,
            {
              content: 'Shipping',
              styles: { halign: 'right' }
            },
            {
              content: formatNumber(
                this.specificExchangeRate(this.deliveryCost),
                2
              ),
              styles: { halign: 'right' }
            }
          ],
          [
            null,
            null,
            null,
            null,
            null,
            null,
            {
              content: 'Total',
              styles: { halign: 'right' }
            },
            {
              content: `${this.toCurrencyLable(this.currency)}  ${formatNumber(
                this.totalIncludedTax,
                2
              )}`,
              styles: { halign: 'right' }
            }
          ]
        ]
        this.cart.push(...summary)
        this.firstRender = false
      }
      const doc = new jsPDF()
      doc.setFont('Kanit-Regular', 'normal') // set font

      doc.addImage(require('../assets/img/LogoHello.jpg'), 'JPEG', 5, 5, 50, 20)
      doc.setFontSize(25)
      doc.text('PURCHASE ORDER', 200, 15, {
        align: 'right'
      })
      doc.setFontSize(15)
      doc.setTextColor('#C30505')
      doc.text('Hello', 10, 32)
      doc.setTextColor('#000000')
      doc.text('Custom', 24, 32)

      doc.setFontSize(10)
      doc.text('All about Accessories for Your Bike', 10, 38)

      doc.text(
        'Hello Custom\n' +
          'Bang Khlo, Bang Kho Laem, Bangkok 10120\n' +
          '+66 95 689 4280\n' +
          'hellocst209@gmail.com\n',
        10,
        50
      )

      doc.text('DATE', 170, 50, {
        align: 'right'
      })
      doc.text(`${moment().format('MMMM Do YYYY')}`, 175, 50)

      doc.text('REFERENCE NUMBER', 170, 54, {
        align: 'right'
      })
      doc.text(`${this.cartDetail.reference_number}`, 175, 54)

      doc.text('VENDOR', 10, 80)
      doc.text(
        'Hello Custom\n' +
          '258/6 Soi 12 Praram 3\n' +
          'Bang Khlo, Bang Kho Laem, Bangkok 10120\n' +
          '+66 95 689 4280\n',
        30,
        80
      )

      doc.text('SHIP TO', 120, 80)
      doc.text(
        `${this.mainAddress.fullName}\n` +
          `${this.mainAddress.addressName}\n` +
          `${this.mainAddress.cityName}  ${this.mainAddress.countryName}  ${this.mainAddress.zipCode}\n` +
          `${this.mainAddress.mobilePhone}\n`,
        140,
        80
      )
      var headers = [
        { content: 'Brand', styles: { halign: 'center' } },
        { content: 'Category', styles: { halign: 'center' } },
        { content: 'No.', styles: { halign: 'center' } },
        { content: 'SKU', styles: { halign: 'center' } },
        { content: 'Name', styles: { halign: 'center' } },
        { content: 'Qty.', styles: { halign: 'center' } },
        { content: 'Price', styles: { halign: 'center' } },
        { content: 'Total', styles: { halign: 'center' } }
      ]
      doc.autoTable({
        headStyles: { fillColor: '#F4D1D1', textColor: 'black' },
        columnStyles: {
          7: { cellWidth: 25 }
          // etc
        },
        styles: { font: 'Kanit-Regular' },
        margin: { top: 105, right: 5, left: 5 },
        head: [headers],
        body: this.cart
      })

      doc.setFont('Kanit-Regular', 'normal')
      doc.text('Authorized Signature', 10, 272)
      doc.line(10, 275, 110, 275)
      doc.text('Date', 140, 272)
      doc.line(140, 275, 200, 275)

      doc.save(`${this.cartDetail.reference_number} - ${moment().unix()}.pdf`)
    }
  }
}
</script>

<style>
</style>